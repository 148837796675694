<template>
  <div :id="`main${position}`" style="width: 100%; height: 100vh"></div>
</template>
<script>
import * as echarts from "echarts";

export default {
  name: "BarEcharts",
  props: { results: Object, position: String },
  components: {},
  data: function () {
    return {};
  },
  watch: {
    results() {
      var chartDom = document.getElementById("main" + this.position);
      var myChart = echarts.init(chartDom);
      myChart.setOption(this.option);
    },
  },
  mounted() {
    if (Object.keys(this.results).length !== 0) {
      var chartDom = document.getElementById("main" + this.position);
      var myChart = echarts.init(chartDom);
      myChart.setOption(this.option);
    }
  },
  computed: {
    candidates() {
      return this.$store.getters["Home/candidateStats"];
    },
    data() {
      const data = this.results.datasets[0].bardata;
      const backgroundColor = this.results.datasets[0].backgroundColor;
      return data.map((el, i) => {
        const obj = { itemStyle: {} };
        obj.value = el;
        obj.itemStyle.color = backgroundColor[i];
        return obj;
      });
    },
    option() {
      return {
        xAxis: {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            interval: 0,
            rotate: 30, //If the label names are too long you can manage this by rotating the label.
          },
          data: this.results.labels,
        },
        grid: { containLabel: true },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.data,
            type: "bar",
          },
        ],
      };
    },
  },
  methods: {},
};
</script>
