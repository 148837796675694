<template>
  <v-container>
    <v-overlay
      opacity="0"
      v-if="pollEntry && pollEntry.submitted"
      absolute
      class="text-center black--text"
    >
      <p class="text-h2 mb-5">You have finished voting</p>
      <v-btn :to="{ name: 'MyVote' }" color="primary" tile x-large>
        View Results
        <v-icon right>mdi-arrow-right</v-icon>
      </v-btn>
    </v-overlay>

    <v-overlay
      opacity="0"
      v-if="!pollEntry || !pollEntry.submitted"
      absolute
      class="text-center black--text"
    >
      <p class="text-h3 mb-5">Begin voting process</p>

      <v-btn
        :loading="!election"
        color="primary"
        @click="agreeToTerms"
        tile
        x-large
      >
        Start voting
        <v-icon right>mdi-arrow-right</v-icon>
      </v-btn>
    </v-overlay>
  </v-container>
</template>

<script>
import { EventBus } from "@/utils/eventBus";

export default {
  name: "terms",
  mounted() {
    EventBus.$on("agreed-to-terms", () => {
      this.$router.push({ name: "Elect" });
    });

    EventBus.$on("terms-failed", (message) => {
      this.$alert({
        title: "Invalid operation",
        text: message,
      });
    });
  },

  methods: {
    agreeToTerms: function () {
      if (!this.pollEntry) {
        this.$store.dispatch("Voting/agreeToTerms", {
          pollingStation: this.election.pollingStations[0].code,
        });
      } else {
        delete this.pollEntry.votingEntry;
        this.$store.dispatch("Voting/pollEntryReporting", {
          ...this.pollEntry,
        });
      }
    },
  },

  computed: {
    election() {
      return this.$store.getters["Voting/elections"];
    },

    pollEntry() {
      return this.$store.getters["Voting/pollEntry"];
    },
  },

  watch: {
    election: {
      handler: function () {
        if (!this.election) this.$router.push({ name: "Home" });
      },
    },
  },
};
</script>

<style scoped></style>
