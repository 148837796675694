import { appName } from "@/environment";
class AdminAuthService {
  constructor() {
    this.token = window.localStorage.getItem(
      `${"admin" + appName || "voter_app"}_token`
    );
    this.user = JSON.parse(
      window.localStorage.getItem(`${"admin" + appName}_user`)
    );
  }

  check() {
    return !!this.token;
  }

  setUser(user) {
    window.localStorage.removeItem(`${"admin" + appName}_user`);
    window.localStorage.setItem(
      `${"admin" + appName}_user`,
      JSON.stringify(user)
    );

    this.user = user;
    location.reload();
  }

  login(token, user) {
    window.localStorage.setItem(`${"admin" + appName}_token`, token);
    window.localStorage.setItem(
      `${"admin" + appName}_user`,
      JSON.stringify(user)
    );

    this.token = token;
    this.user = user;

    location.reload();
  }

  logout() {
    window.localStorage.removeItem(`${"admin" + appName}_token`);
    window.localStorage.removeItem(`${"admin" + appName}_user`);
    window.location.reload();
  }
}

export default new AdminAuthService();
