import call from "@/service/http";
import constants from "./votingConstants";
import { EventBus } from "@/utils/eventBus";

export default {
  namespaced: true,
  state: {
    elections: [],
    pollEntry: [],
  },
  mutations: {
    SE_ELECTIONS: (state, payload) => {
      state.elections = payload;
    },

    SET_POLL_ENTRY: (state, payload) => {
      state.pollEntry = payload;
    },
  },
  getters: {
    elections: (state) => state.elections[0],
    pollEntry: (state) => state.pollEntry[0],
  },
  actions: {
    getElections: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.elections)
        .then((res) => {
          commit("SE_ELECTIONS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("ApiError", "Unable to fetch elections");
        });
    },

    getPollEntry: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.pollEntry)
        .then((res) => {
          commit("SET_POLL_ENTRY", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("ApiError", "Unable to fetch elections");
        });
    },

    agreeToTerms: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.pollEntryTerms, data)
        .then((res) => {
          commit("SET_POLL_ENTRY", res.data.data);
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("agreed-to-terms");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("terms-failed", err.response.data.message);
        });
    },

    pollEntryReporting: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.pollEntryReport, data)
        .then((res) => {
          commit("SET_POLL_ENTRY", res.data.data);
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("agreed-to-terms");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("terms-failed", err.response.data.message);
        });
    },

    submitPollEntry: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.pollEntrySubmit, data)
        .then(() => {
          EventBus.$emit("voting-submitted");
          dispatch("getPollEntry");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("terms-failed", err.response.data.message);
        });
    },

    saveVotingEntry: ({ commit, dispatch }, data) => {
      console.log(data);
      commit("SET_LOADING", true, { root: true });
      call("post", constants.voteEntry, data)
        .then(() => {
          EventBus.$emit("save-entry");
          dispatch("getPollEntry");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("voting-error", err.response.data.message);
        });
    },

    sendVotingOtp: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.sendVotingOtp, data)
        .then(() => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("voting-otp-sent");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("voting-otp-error", err.response.data.message);
        });
    },

    verifyVotingOtp: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.verifyVotingOtp, data)
        .then(() => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("voting-otp-success");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("voting-otp-failed", err.response.data.message);
        });
    },
  },
};
