<template>
  <v-app>
    <v-main>
      <router-view />
      <loader />
      <app-alert />
      <AppFooter />
    </v-main>
  </v-app>
</template>
<script>
import AdminAuthService from "../adminAuthService";
import AppFooter from "@/components/app-footer";
export default {
  name: "AdminAuth",
  components: { AppFooter },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (AdminAuthService.check() && AdminAuthService.user.isAdmin === 1) {
        v.$router.replace({ name: "Dashboard" });
      }
    });
  },
};
</script>
