import votingLayout from "@/modules/voting/views/votingLayout";
import terms from "@/modules/voting/components/terms";
import elect from "@/modules/voting/components/elect";
import { Auth } from "@/modules/auth";

export default [
  {
    path: "/voting",
    component: votingLayout,
    children: [
      {
        path: "",
        name: "Terms",
        component: terms,
        meta: { middleware: [Auth] },
      },
      {
        path: "elect",
        name: "Elect",
        component: elect,
        meta: { middleware: [Auth] },
      },
    ],
  },
];
