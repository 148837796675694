export default {
  elections: "election",
  pollEntry: "election/pollentry",
  pollEntryTerms: "election/pollentry/terms",
  pollEntryReport: "election/pollentry/report",
  pollEntrySubmit: "election/pollentry/submit",
  voteEntry: "election/votingentry",
  sendVotingOtp: "election/pollentry/sendOtp",
  verifyVotingOtp: "election/pollentry/verify",
};
