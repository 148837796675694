<template>
  <v-app>
    <v-app-bar app>
      <div>
        <v-img contain max-height="100" class="mr-2" :src="company?.link" />
      </div>
      {{ env.VUE_APP_NAME }}
      <div class="mx-2">Scrutineer's Dashboard</div>
      <v-spacer />
      <v-spacer></v-spacer>
      <v-chip class="mx-2" label>
        Registered Voters
        <v-divider vertical class="mx-2" />
        {{ electionStats.voterStats || 0 }}
      </v-chip>
      <v-chip class="mx-2" label>
        Voter Turnout
        <v-divider vertical class="mx-2" />
        {{ `${turnout} %` || `${0} %` }}
      </v-chip>
      <v-chip class="mx-2" label>
        Votes Cast
        <v-divider vertical class="mx-2" />
        {{ this.electionStats.pollCount || 0 }}
      </v-chip>
      <!-- <v-chip class="mx-2" label>
        cast Votes
        <v-divider vertical class="mx-2" />
        {{ electionStats.voteCount || 0 }}
      </v-chip> -->
      <v-btn color="primary" text class="mx-2" @click="refresh">
        <v-icon>mdi-refresh</v-icon>
        refresh
      </v-btn>
      <v-btn tile @click="$store.dispatch('AdminAuth/logout')">
        logout <v-icon class="mx-2">mdi-logout-variant</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main class="background">
      <router-view />
    </v-main>

    <loader />
    <AppFooter />
    <app-alert />
  </v-app>
</template>
<script>
import AppFooter from "@/components/app-footer";
import { AuthService } from "@/modules/auth";
export default {
  name: "DashboardLayout",
  components: { AppFooter },
  data: function () {
    return {
      env: process.env,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Admin/getResults");
      v.$store.dispatch("Setup/getCompany");
    });
  },
  computed: {
    company() {
      return this.$store.getters["Setup/SETUPGETTERS"]("company");
    },
    auth() {
      return AuthService;
    },
    election() {
      return this.$store.getters["Admin/dashboardGetter"]("election") || [];
    },
    voterStats() {
      return this.$store.getters["Home/voterStats"] || [];
    },

    electionStats() {
      return this.$store.getters["Home/electionStats"] || [];
    },

    turnout() {
      return parseFloat(
        (this.electionStats.pollCount / this.electionStats.voterStats) * 100
      ).toFixed(2);
    },
  },
  methods: {
    refresh() {
      this.$store.dispatch("Admin/getResults");
    },
  },
  watch: {
    election: {
      handler: function () {
        this.$store.dispatch("Home/getElectionStats", {
          entity: "VoteManager_electionStats",
          electionCode: this.election.code,
        });

        this.$store.dispatch("Home/getCandidateStats", {
          entity: "VoteManager_candidateStats",
          electionCode: this.election.code,
        });

        this.$store.dispatch("Home/getVoterStats", {
          entity: "VoteManager_voterStats",
          electionCode: this.election.code,
        });
      },
    },
  },
};
</script>
