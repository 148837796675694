import call from "@/service/http/client/admin.js";
import constants from "./AdminAuthConstants";
import AdminAuthService from "./adminAuthService";
import { EventBus } from "@/utils/eventBus";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    login: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("Auth/SET_ALERT", null, { root: true });

      call("post", constants.login, data)
        .then((res) => {
          if (res.data.data.token) {
            AdminAuthService.login(res.data.data.token, res.data.data.user);
            commit("SET_LOADING", false, { root: true });
          } else {
            EventBus.$emit("otp-verification", res.data.data);
          }
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit(
            "Auth/SET_ALERT",
            {
              status: "error",
              message: err.response.data.message,
            },
            { root: true }
          );
        });
    },
    logout: ({ commit }) => {
      commit("Auth/SET_ALERT", null, { root: true });
      commit("SET_LOADING", true, { root: true });
      call("get", constants.logout)
        .then(() => {
          commit("SET_LOADING", false, { root: true });
          AdminAuthService.logout();
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit(
            "Auth/SET_ALERT",
            {
              status: "error",
              message: err.response.data.message,
            },
            { root: true }
          );
        });
    },

    forgotPassword: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("Auth/SET_ALERT", null, { root: true });

      call("post", constants.resetPassword, data)
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("forgot-otp-sent", res.data.message);
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit(
            "Auth/SET_ALERT",
            {
              status: "error",
              message: err.response.data.message,
            },
            { root: true }
          );
        });
    },

    resetPassword: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("Auth/SET_ALERT", null, { root: true });

      call("post", constants.setPassword, data)
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          AdminAuthService.login(res.data.data.token, res.data.data.user);
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit(
            "Auth/SET_ALERT",
            {
              status: "error",
              message: err.response.data.message,
            },
            { root: true }
          );
        });
    },

    sendOtp: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);
      call("post", constants.sendOtp, data)
        .then(() => {
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    verifyOtp: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);

      call("post", constants.verifyOtp, data)
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          AdminAuthService.login(res.data.data.token, res.data.data.user);
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    verifyOtpReset: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      commit("SET_ALERT", null);

      call("post", constants.verifyReset, data)
        .then(() => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("otp-verification-success");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};
