<template>
  <v-container class="fill-height d-flex flex-column align-content-center">
    <v-overlay opacity="0" class="black--text" absolute>
      <v-row style="width: 90vw">
        <v-col cols="12" md="4" offset-md="4">
          <v-card tile light>
            <v-card-title> Login to voter account </v-card-title>

            <v-card-text class="mt-5">
              <auth-alert />

              <v-form ref="loginForm" v-model="isValid">
                <!--User name-->
                <v-text-field
                  @keyup.enter.prevent="login"
                  ref="email"
                  label="Email address"
                  :rules="rules.email"
                  v-model="formData.email"
                  outlined
                  placeholder="Enter email address"
                />

                <!-- Password -->
                <v-text-field
                  @keyup.enter.prevent="login"
                  ref="password"
                  type="password"
                  :rules="rules.password"
                  label="Password"
                  v-model="formData.password"
                  outlined
                  placeholder="Enter Password"
                />
              </v-form>

              <v-btn
                @keyup.enter.prevent="login"
                @click="login"
                color="primary"
                block
                class="mt-5"
                tile
              >
                Login
                <v-icon right>mdi-login</v-icon>
              </v-btn>
            </v-card-text>

            <v-card-actions class="mt-5">
              <v-btn
                class="text-left"
                :to="{ name: 'forgotPassword' }"
                text
                tile
              >
                Forgot Password ?
              </v-btn>

              <v-spacer />

              <v-btn text tile :to="{ name: 'Verify' }"> Verify details </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-overlay>

    <request-access />
  </v-container>
</template>

<script>
import AuthAlert from "@/modules/auth/components/authAlert";
import { EventBus } from "@/utils/eventBus";
import RequestAccess from "@/modules/auth/components/requestAccess";

export default {
  name: "login",
  components: { RequestAccess, AuthAlert },
  data: function () {
    return {
      dialog: false,
      token: "",
      isValid: false,
      isValidSearch: false,
      isValidPassword: false,
      step: "login",
      steps: ["login", "verify", "password"],
      memberNo: "",
      formData: {
        email: "",
        password: "",
        token: "",
      },
      rules: {
        email: [
          (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
        ],
        required: [(value) => !!value || "Required."],
      },
      viewPassword: false,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (v.$route.query.memberNo && v.$route.query.voterToken) {
        v.token = v.$route.query.voterToken;
        v.memberNo = v.$route.query.memberNo;
        v.step = "password";
      }
    });
  },

  mounted() {
    EventBus.$on("search-success", () => {
      this.step = "verify";
    });

    EventBus.$on("search-failed", (message) => {
      this.$alert({
        title: "Voter not found",
        text: message,
      });
    });

    EventBus.$on("token-verification-success", () => {
      this.step = "password";
      this.dialog = false;
    });

    EventBus.$on("token-verification-failed", (message) => {
      this.$alert({
        title: "Invalid voter token",
        text: message,
      });
    });

    EventBus.$on("token-sending-failed", (message) => {
      this.$alert({
        title: "Token not sent",
        text: message,
      });
    });
  },

  computed: {
    results() {
      return this.$store.getters["Auth/results"][0];
    },
  },

  methods: {
    login: function () {
      this.$refs.loginForm.validate();
      if (this.isValid) {
        this.$store.dispatch("Auth/login", this.formData);
      }
    },

    search: function () {
      this.$refs.searchForm.validate();
      if (this.isValidSearch) {
        this.$store.dispatch("Auth/search", { memberNo: this.memberNo });
      }
    },

    setPassword: function () {
      this.$refs.passwordForm.validate();
      if (this.isValidPassword) {
        this.$store.dispatch("Auth/setPassword", {
          ...this.formData,
          voterToken: this.token,
        });
      }
    },

    sendToken: function (type) {
      this.$store.dispatch("Auth/sendToken", {
        user: { ...this.results },
        type: type,
      });
    },

    verifyToken: function () {
      this.$store.dispatch("Auth/verifyToken", {
        user: { ...this.results },
        voterToken: this.token,
      });
    },

    emailMask: function (email) {
      if (!email) return "";

      const index = email.lastIndexOf("@");
      const prefix = email.substring(0, index);
      const postfix = email.substring(index);

      const mask = prefix
        .split("")
        .map(function (o, i) {
          if (i === 0 || i === index - 1) {
            return o;
          } else {
            return "*";
          }
        })
        .join("");

      return mask + postfix;
    },

    phoneMask: function (phoneNumber) {
      return phoneNumber ? phoneNumber.replace(/\d{5}$/, "*****") : "";
    },
  },
};
</script>

<style scoped>
@import url("../styles.css");

.v-input__append-outer {
  margin-top: 8px;
}
</style>
