<template>
  <v-row>
    <v-col cols="12" md="6" lg="6" sm="12">
      <BarEcharts :results="dataBarcollection" :position="'All'" />
    </v-col>
    <v-col cols="12" md="6" lg="6" sm="12">
      <doughnut-chart v-if="type === 'doughnut'" :chart-data="datacollection" />
    </v-col>
  </v-row>
</template>
<script>
import DoughnutChart from "@/modules/admin/charts/doughnut.js";
import helpers from "@/utils/helpers/helper.js";
import BarEcharts from "./BarEcharts.vue";

export default {
  name: "CandidateChart",
  props: { results: Array, type: { type: String, default: "bar" } },
  components: { BarEcharts, DoughnutChart },
  data: function () {
    return {};
  },
  mounted() {},
  computed: {
    datacollection() {
      return {
        labels: this.results.map((item) => {
          return `${item.firstName}`;
        }),
        datasets: [
          {
            label: "Votes Per Candidate",
            backgroundColor: this.results.map((item) => {
              return helpers.stringToColour(item.firstName);
            }),
            data: this.results.map((item) => {
              return parseInt(item.votePercent);
            }),
          },
        ],
      };
    },
    dataBarcollection() {
      return {
        labels: this.results.map((item) => {
          return `${item.firstName}`;
        }),
        datasets: [
          {
            label: "Votes Per Candidate",
            backgroundColor: this.results.map((item) => {
              return helpers.stringToColour(item.firstName);
            }),
            bardata: this.results.map((item) => {
              return parseInt(item.votes);
            }),
          },
        ],
      };
    },
  },
  methods: {},
};
</script>
