<template>
  <v-container class="my-7" fluid>
    <v-stepper v-model="step" width="100%" tile flat alt-labels>
      <v-stepper-header>
        <template v-for="(pos, index) in positions">
          <v-stepper-step
            :step="index + 1"
            :key="index"
            :complete="step > index + 1"
          >
            <small>{{ pos.description }}</small></v-stepper-step
          >
          <v-divider :key="`d${index}`" v-if="index < positions.length - 1" />
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          :step="index + 1"
          v-for="(pos, index) in positions"
          :key="index"
        >
          <v-card
            v-if="pos.instructions"
            class="mb-12"
            flat
            color="transparent"
            tile
          >
            <v-card-title class="primary--text">
              Voting Instructions
            </v-card-title>

            <v-card-text>
              <v-list dense>
                <v-list-item
                  dense
                  v-for="(pos, i) in availablePostions"
                  :key="i"
                >
                  <v-list-item-icon>
                    <v-chip color="transparent"> Step {{ i + 1 }} </v-chip>
                  </v-list-item-icon>
                  <v-list-item-content>
                    Select {{ pos.maxPositions > 1 ? "a maximum of" : "only" }}
                    {{ numToWords(pos.maxPositions) }} ({{ pos.maxPositions }})
                    {{ pos.maxPositions > 1 ? "candidates" : "candidate" }} for
                    {{ pos.description }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-chip color="transparent">
                      Step {{ positions.length - 1 }}
                    </v-chip>
                  </v-list-item-icon>
                  <v-list-item-content>
                    Review your choices for various elective seats, if not
                    satisfied go back to specific elective seat and make
                    changes, if satisfied click on finish button to view summary
                    and submit
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-chip-group>
                      <v-chip color="primary"> NB </v-chip>
                    </v-chip-group>
                    To select a candidate click or press a candidates name and
                    to change a candidate you must first unselect the selected
                    candidate
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions class="pa-7">
              <v-btn @click="prev(null)" :disabled="step === 1">
                <v-icon left>mdi-arrow-left</v-icon>
                Previous
              </v-btn>

              <v-spacer />

              <v-btn color="primary" @click="next(null)">
                {{ step === positions.length ? "View Summary" : "Next" }}
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-card
            v-if="!pos.instructions && !pos.summary"
            class="mb-12"
            flat
            color="transparent"
            tile
          >
            <v-card-title class="primary--text">
              Select {{ pos.positions }} {{ pos.description }}
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col
                  cols="6"
                  md="3"
                  v-for="(candidate, i) in loadash.orderBy(
                    pos.candidateElePositions,
                    (c) => c.candidateSequence
                  )"
                  :key="i"
                >
                  <v-card
                    :color="
                      elected[pos.code].selected.includes(candidate.candidateNo)
                        ? 'success lighten-5'
                        : 'white'
                    "
                    outlined
                    :raised="
                      elected[pos.code].selected.includes(candidate.candidateNo)
                    "
                    :disabled="
                      !elected[pos.code].selected.includes(
                        candidate.candidateNo
                      ) &&
                      elected[pos.code].selected.length === pos.maxPositions
                    "
                    class="pa-5"
                  >
                    <v-card-subtitle class="overline pa-0 ma-0 text-center">
                      {{ candidate.electionCandidates[0].memberNo || "" }}
                    </v-card-subtitle>
                    <v-img
                      aspect-ratio="1"
                      height="150px"
                      contain
                      lazy-src="/images/loading.gif"
                      :src="candidate.link"
                    />
                    <v-card-subtitle
                      class="caption pa-0 ma-0 d-flex flex-row justify-center"
                    >
                      <v-chip-group>
                        <v-chip
                          x-small
                          v-if="candidate.electionCandidates[0].memberCategory"
                        >
                          {{
                            candidate.electionCandidates[0].memberCategory || ""
                          }}
                        </v-chip>
                        <v-chip
                          x-small
                          v-if="candidate.electionCandidates[0].discipline"
                        >
                          {{ candidate.electionCandidates[0].discipline || "" }}
                        </v-chip>
                      </v-chip-group>
                    </v-card-subtitle>
                    <v-card-actions>
                      <v-checkbox
                        class="overline"
                        color="success"
                        hide-details
                        :value="candidate.candidateNo"
                        :label="`${candidate.electionCandidates[0].title} ${candidate.candidateName}`"
                        v-model="elected[pos.code].selected"
                      />
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="pa-7">
              <v-btn @click="prev(pos)" :disabled="step === 0">
                <v-icon left>mdi-arrow-left</v-icon>
                Previous
              </v-btn>

              <v-spacer />

              <v-btn
                v-if="pos.candidateElePositions.length > 0"
                text
                @click="skip(pos)"
                :disabled="elected[pos.code].selected.length > 0"
              >
                Skip
                <v-icon right>mdi-debug-step-over</v-icon>
              </v-btn>

              <v-btn
                v-if="pos.candidateElePositions.length > 0"
                color="primary"
                @click="next(pos)"
                :disabled="
                  elected[pos.code].selected.length < pos.minpositions ||
                  elected[pos.code].selected.length > pos.maxPositions
                "
              >
                {{ step === positions.length ? "View Summary" : "Next" }}
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>

              <v-btn v-else color="primary" @click="next(null)">
                {{ step === positions.length ? "View Summary" : "Next" }}
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card
            v-if="pos.summary"
            class="mb-12"
            flat
            color="transparent"
            tile
          >
            <v-card-title class="primary--text">
              <v-spacer />

              {{
                pollEntry
                  ? !pollEntry.submitted
                    ? "Submit your candidate selection to finish voting"
                    : "My votes"
                  : ""
              }}

              <v-spacer />

              <v-btn
                v-if="pollEntry && !pollEntry.submitted"
                color="primary"
                tile
                @click="sendOtp"
              >
                Submit Vote
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  v-for="(voted, i) in votingEntry"
                  :key="i"
                >
                  <v-list three-line>
                    <v-list-item-group>
                      <v-list-item>
                        <v-list-item-avatar size="70" tile>
                          <v-img
                            aspect-ratio="1"
                            contain
                            lazy-src="/images/loading.gif"
                            :src="voted.link"
                          />
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-subtitle>
                            <v-chip small>
                              {{ voted.electivePositions.description }}
                            </v-chip>
                          </v-list-item-subtitle>
                          <v-list-item-title class="text-uppercase">
                            {{
                              `${voted.electionCandidates[0].title} ${voted.electionCandidates[0].firstName}
                                                        ${voted.electionCandidates[0].secondName} ${voted.electionCandidates[0].lastName}`
                            }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Voted at: {{ formatDate(voted.votedTime, 7) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pa-7">
              <v-btn @click="prev(null)" :disabled="step === 1">
                <v-icon left>mdi-arrow-left</v-icon>
                Previous
              </v-btn>
            </v-card-actions>

            <v-dialog v-model="dialog" width="500" persistent>
              <v-card>
                <v-card-title class="primary lighten-1">
                  Verify OTP
                </v-card-title>
                <v-card-text class="pa-5">
                  <span class="caption">
                    Check your phone or email for the OTP Code
                  </span>

                  <v-otp-input v-model="otp" length="6" @finish="verifyOtp" />
                </v-card-text>

                <v-divider />

                <v-card-actions>
                  <v-btn @click="sendOtp" :loading="$store.getters['loading']">
                    Resend
                  </v-btn>
                  <v-spacer />
                  <v-btn color="primary" @click="verifyOtp"> Verify </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import _ from "lodash";
import { EventBus } from "@/utils/eventBus";
import DateMixin from "@/mixin/DateMixin";

export default {
  name: "elect",
  mixins: [DateMixin],
  data() {
    return {
      step: 1,
      elected: {},
      dialog: false,
      otp: "",
      forward: true,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Voting/getPollEntry");
    });
  },

  beforeRouteLeave(to, from, next) {
    if (this.pollEntry && !this.pollEntry.submitted) {
      this.$confirm.show({
        title: "Votes not submitted",
        text: "You have not submitted your votes, are you sure you want to leave?",
        onConfirm: () => {
          next();
        },
        onCancel: () => {
          next(false);
        },
      });
    }

    next();
  },

  mounted() {
    EventBus.$on("save-entry", () => {
      if (this.forward) {
        if (this.step < this.positions.length) this.step++;
      }

      if (!this.forward) {
        if (this.step > 1) this.step--;
      }
    });

    EventBus.$on("voting-error", (message) => {
      this.$alert({
        title: "Invalid Operation",
        text: message,
      });
    });

    EventBus.$on("voting-otp-success", () => {
      this.submitPollEntry();
    });

    EventBus.$on("voting-submitted", () => {
      this.$alert({
        title: "Voting Completed",
        text: "You have successfully completed voting",
        onClose: () => {
          this.$router.push({ name: "Home" });
        },
      });
    });

    EventBus.$on("voting-otp-failed", (message) => {
      this.$alert({
        title: "Invalid Operation",
        text: message,
      });
    });

    EventBus.$on("voting-otp-error", (message) => {
      this.$alert({
        title: "Invalid Operation",
        text: message,
      });
    });
  },

  computed: {
    availablePostions() {
      return this.positions.filter((p) => !p.instructions && !p.summary);
    },
    loadash() {
      return _;
    },

    positions() {
      let pos = this.election
        ? _.orderBy(this.election.electivePositions, (p) => p.sequence)
        : [];

      pos.unshift({ instructions: true, description: "Instructions" });
      pos.push({ summary: true, description: "Summary" });
      return pos;
    },

    election() {
      return this.$store.getters["Voting/elections"];
    },

    pollEntry() {
      return this.$store.getters["Voting/pollEntry"];
    },

    votingEntry() {
      return this.pollEntry
        ? _.orderBy(
            this.pollEntry.votingEntry,
            (p) => p.electivePositions.sequence
          )
        : [];
    },
  },

  methods: {
    saveSingleCandidate: function (pos) {
      this.$store.dispatch("Voting/saveVotingEntry", {
        selected: [
          {
            pollingStation: this.election.pollingStations[0].code,
            candidateCode: this.elected[pos.code].selected[0],
            position: pos.code,
          },
        ],
      });
    },

    saveMultipleCandidates: function (pos) {
      let payload = [];
      this.elected[pos.code].selected.forEach((c) => {
        payload.push({
          pollingStation: this.election.pollingStations[0].code,
          candidateCode: c,
          position: pos.code,
        });
      });

      this.$store.dispatch("Voting/saveVotingEntry", { selected: payload });
    },

    next: function (pos) {
      this.forward = true;

      if (!pos) {
        EventBus.$emit("save-entry");
        return;
      }

      if (pos.maxPositions > 1) {
        this.saveMultipleCandidates(pos);
      } else {
        this.saveSingleCandidate(pos);
      }
    },

    prev: function (pos) {
      this.forward = false;

      if (!pos) {
        EventBus.$emit("save-entry");
        return;
      }

      if (this.elected[pos.code].selected.length === 0) {
        EventBus.$emit("save-entry");
        return;
      }

      if (pos.maxPositions > 1) {
        this.saveMultipleCandidates(pos);
      } else {
        this.saveSingleCandidate(pos);
      }
    },

    skip: function (pos) {
      this.forward = true;

      this.$confirm.show({
        title: "Skip Candidate",
        text: "Do you want to skip electing for this position?",
        onConfirm: () => {
          this.$store.dispatch("Voting/saveVotingEntry", {
            selected: [
              {
                pollingStation: this.election.pollingStations[0].code,
                candidateCode: "skip",
                position: pos.code,
              },
            ],
          });

          //call bc with empty
          // this.step++;
        },
      });
    },

    numToWords: function (number) {
      if (!number) return "";
      number = parseInt(number);
      const first = [
        "",
        "one ",
        "two ",
        "three ",
        "four ",
        "five ",
        "six ",
        "seven ",
        "eight ",
        "nine ",
        "ten ",
        "eleven ",
        "twelve ",
        "thirteen ",
        "fourteen ",
        "fifteen ",
        "sixteen ",
        "seventeen ",
        "eighteen ",
        "nineteen ",
      ];
      const tens = [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ];
      const mad = ["", "thousand", "million", "billion", "trillion"];
      let word = "";

      for (let i = 0; i < mad.length; i++) {
        let tempNumber = number % (100 * Math.pow(1000, i));
        if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
          if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
            word =
              first[Math.floor(tempNumber / Math.pow(1000, i))] +
              mad[i] +
              " " +
              word;
          } else {
            word =
              tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] +
              "-" +
              first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] +
              mad[i] +
              " " +
              word;
          }
        }

        tempNumber = number % Math.pow(1000, i + 1);
        if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0)
          word =
            first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] +
            "hunderd " +
            word;
      }
      return word;
    },

    setVotedEntries: function () {
      if (this.pollEntry)
        this.pollEntry.votingEntry.forEach((v) => {
          if (
            !this.elected[v.electivePosition].selected.includes(v.candidateCode)
          )
            this.elected[v.electivePosition].selected.push(v.candidateCode);
        });
    },

    submitPollEntry: function () {
      delete this.pollEntry.votingEntry;
      this.$store.dispatch("Voting/submitPollEntry", { ...this.pollEntry });
    },

    sendOtp: function () {
      delete this.pollEntry.votingEntry;
      this.$store.dispatch("Voting/sendVotingOtp", { ...this.pollEntry });
      this.dialog = true;
    },

    verifyOtp: function () {
      this.$store.dispatch("Voting/verifyVotingOtp", {
        otp: this.otp.toUpperCase(),
      });
      this.dialog = false;
    },
  },

  watch: {
    election: {
      handler: function () {
        let elected = {};
        if (this.election)
          this.election.electivePositions.forEach((p) => {
            elected[p.code] = {
              positions: p.positions,
              selected: [],
            };
          });

        this.elected = { ...elected };
        this.setVotedEntries();
      },
      immediate: true,
    },

    pollEntry: {
      handler: function () {
        if (this.pollEntry.submitted) {
          this.$router.push({ name: "MyVote" });
        }
        this.setVotedEntries();
      },
    },
  },
};
</script>

<style scoped></style>
