import * as env from "@/environment";
import moment from "moment";
import { AuthService } from "@/modules/auth";

export default {
  data: function () {
    return {
      countDown: {
        total: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };
  },

  mounted() {
    setInterval(() => {
      if (this.election) {
        const endtime = this.election.endDate;

        if (moment() > moment(this.election.endDate)) {
          this.countDown = {
            total: 0,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          };

          return;
        }

        const total = Date.parse(endtime) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));

        this.countDown = {
          total,
          days,
          hours,
          minutes,
          seconds,
        };
      }
    }, 1000);

    // setTimeout(() => {
    //   if (!this.election) location.reload();
    // }, 90000);
  },

  computed: {
    env() {
      return env;
    },

    election() {
      return this.$store.getters["Home/elections"];
    },

    canVote() {
      return this.election
        ? moment() > moment(this.election.startDate) &&
            moment() < moment(this.election.endDate)
        : false;
    },

    auth() {
      return AuthService;
    },

    submitted() {
      return this.pollEntry ? this.pollEntry.submitted : false;
    },

    pollEntry() {
      return this.$store.getters["Voting/pollEntry"];
    },
  },
};
