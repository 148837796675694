import { render, staticRenderFns } from "./myVote.vue?vue&type=template&id=6654e359&scoped=true"
import script from "./myVote.vue?vue&type=script&lang=js"
export * from "./myVote.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6654e359",
  null
  
)

export default component.exports