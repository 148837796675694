import { AdminRoutes, AdminStore } from "./modules/dashboard";
import { AuthStore, AuthRoutes } from "./modules/auth";

export default {
  install(Vue, options) {
    options.router.addRoute("/", ...AdminRoutes);
    options.router.addRoute(...AuthRoutes);
    options.store.registerModule("Admin", AdminStore);
    options.store.registerModule("AdminAuth", AuthStore);
  },
};
