<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    style="background-color: rgba(255, 255, 255, 0.65)"
  >
    <v-row>
      <v-col md="4" sm="12" offset-md="4">
        <v-card class="pa-5">
          <v-card-title class="text-h4 d-flex flex-row justify-center">
            SET PASSWORD
          </v-card-title>
          <v-card-subtitle class="text-center">
            Set a new password
          </v-card-subtitle>

          <v-card-text class="text-justify mt-4 pb-0">
            <auth-alert />

            <v-form ref="loginForm" v-model="isValid">
              <v-text-field
                ref="password"
                v-model="formData.password"
                dense
                outlined
                type="password"
                label="New Password"
                placeholder="Enter a secure password"
                :rules="rules.password"
              />

              <v-text-field
                ref="confirmPassword"
                v-model="formData.confirmPassword"
                dense
                outlined
                type="password"
                label="Confirm Password"
                placeholder="Enter Password again"
                :rules="rules.confirmPassword"
              />

              <v-btn
                class="text-left my-3"
                color="primary"
                block
                @click="reset"
              >
                Set Password
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RouterMixin from "@/mixin/RouterMixin";
import { EventBus } from "@/utils/eventBus";
import AuthAlert from "@/modules/auth/components/authAlert";

export default {
  name: "password",
  components: { AuthAlert },
  mixins: [RouterMixin],
  data: function () {
    return {
      isValid: false,
      formData: {
        password: "",
        confirmPassword: "",
      },
    };
  },

  computed: {
    rules() {
      return {
        password: [(v) => !!v || "Password is required"],
        confirmPassword: [
          (v) => !!v || "Password is required",
          (v) => v === this.formData.password || "Password does not match",
        ],
      };
    },
  },

  mounted() {
    EventBus.$on("password-reset-success-admin", () => {
      this.$alert({
        title: "Password set Success",
        text: "You have successfully set your password, proceed to login",
        onClose: () => {
          this.$router.push("/admin-auth/login");
        },
      });
    });
  },

  methods: {
    reset: function () {
      this.$refs.loginForm.validate();
      if (this.isValid) {
        this.formData.otp = this.decodeRoute(this.$route.params.code);
        this.$store.dispatch("AdminAuth/resetPassword", {
          ...this.formData,
        });
      }
    },
  },
};
</script>

<style scoped></style>
