import DashboardLayout from "./views/DashboardLayout";
import Dashboard from "./components/Dashboard";
import Admin from "@/modules/auth/middleware/Admin";

export default [
  {
    path: "/admin",
    component: DashboardLayout,
    children: [
      {
        path: "",
        redirect: "/admin/dashboard",
      },
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: { middleware: [Admin] },
      },
    ],
  },
];
