<template>
  <v-container>
    <v-toolbar color="transparent" flat tile dense class="mb-5">
      <v-toolbar-title> Results </v-toolbar-title>

      <v-spacer />

      <v-btn @click="getStats">
        <v-icon left>mdi-refresh</v-icon>
        Refresh
      </v-btn>
    </v-toolbar>
    <v-row>
      <v-col cols="12" md="4">
        <v-sheet
          rounded
          class="text-center d-flex flex-column justify-center align-center pa-3"
          elevation="1"
          color="white"
        >
          <h1 class="overline text-h3 mt-3">
            {{ electionStats ? electionStats.voterStats : 0 }}
          </h1>
          <p class="mt-3">Registered Voters</p>
        </v-sheet>
      </v-col>

      <v-col cols="12" md="4">
        <v-sheet
          rounded
          class="text-center d-flex flex-column justify-center align-center pa-3"
          elevation="1"
          color="white"
        >
          <h1 class="overline text-h3 mt-3">
            {{ `${turnout} %` || `${0} %` }}
          </h1>
          <p class="mt-3">Voter Turnout</p>
        </v-sheet>
      </v-col>

      <v-col cols="12" md="4">
        <v-sheet
          rounded
          class="text-center d-flex flex-column justify-center align-center pa-3"
          elevation="1"
          color="white"
        >
          <h1 class="overline text-h3 mt-3">
            {{ electionStats ? electionStats.pollCount : 0 }}
          </h1>
          <p class="mt-3">Votes Cast</p>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row v-if="isElectionClosed">
      <v-col cols="12">
        <v-card tile flat class="my-3" v-for="(p, i) in positions" :key="i">
          <v-card-title>
            {{ candidateStats[p.code].datasets[0].label }}
          </v-card-title>
          <bar-chart :data="candidateStats[p.code]" :options="options" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import BarChart from "@/modules/charts/BarChart";
export default {
  name: "results",
  components: { BarChart },
  data: function () {
    return {
      tab: null,
      options: {
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  return value + "";
                },
                max: 100,
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || "";

              if (label) {
                label += ": ";
              }
              label += Math.round(tooltipItem.yLabel * 100) / 100;
              return label + "%";
            },
          },
        },
      },
      dataset: {},
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (v.election) v.getStats();
    });
  },

  computed: {
    positions() {
      return this.election
        ? _.orderBy(this.election.electivePositions, (p) => p.sequence)
        : [];
    },

    election() {
      return this.$store.getters["Home/elections"];
    },

    isElectionClosed() {
      return this.election ? moment() > moment(this.election.endDate) : false;
    },

    voterStats() {
      return this.$store.getters["Home/voterStats"];
    },

    electionStats() {
      return this.$store.getters["Home/electionStats"];
    },

    turnout() {
      return this.electionStats
        ? parseFloat(
            (this.electionStats.pollCount / this.electionStats.voterStats) * 100
          ).toFixed(2)
        : 0.0;
    },

    candidateStats() {
      let positions = {};
      let candidateStats = this.$store.getters["Home/candidateStats"];

      if (!candidateStats) return null;

      this.positions.forEach((p) => {
        positions[p.code] = {
          labels: [],
          datasets: [
            {
              label: "",
              backgroundColor: "#f87979",
              data: [],
            },
          ],
        };

        positions[p.code].datasets[0].label = p.description;

        let candidates = _.orderBy(
          p.candidateElePositions,
          (c) => c.voteCount,
          "desc"
        );

        let totalPositionVotes = 0;

        candidates.forEach(
          (c) => (totalPositionVotes += parseInt(c.voteCount))
        );

        candidates.forEach((c) => {
          positions[p.code].labels.push(c.candidateName);
          positions[p.code].datasets[0].data.push(
            // parseInt(candidateStats[`{${c.candidateNo.toUpperCase()}}`])
            ((c.voteCount * 100) / totalPositionVotes).toFixed(2)
          );
        });
      });

      return positions;
    },
  },

  methods: {
    getStats: function () {
      this.$store.dispatch("Home/getCandidateStats", {
        entity: "VoteManager_candidateStats",
        electionCode: this.election.code,
      });

      this.$store.dispatch("Home/getElectionStats", {
        entity: "VoteManager_electionStats",
        electionCode: this.election.code,
      });

      this.$store.dispatch("Home/getVoterStats", {
        entity: "VoteManager_voterStats",
        electionCode: this.election.code,
      });
    },
  },

  watch: {
    election: {
      handler: function () {
        this.getStats();
      },
    },
  },
};
</script>

<style scoped></style>
