import call from "@/service/http";
import constants from "./homeConstants";
import { EventBus } from "@/utils/eventBus";

export default {
  namespaced: true,
  state: {
    elections: [],
    registrations: [],
    voterStats: null,
    electionStats: null,
    candidateStats: null,
  },
  mutations: {
    SE_ELECTIONS: (state, payload) => {
      state.elections = payload;
    },

    SET_REGISTRATIONS: (state, payload) => {
      state.registrations = payload;
    },

    SET_CANDIDATE_STATS: (state, payload) => {
      state.candidateStats = payload;
    },

    SET_ELECTION_STATS: (state, payload) => {
      state.electionStats = payload;
    },

    SET_VOTER_STATS: (state, payload) => {
      state.voterStats = payload;
    },
  },
  getters: {
    elections: (state) => state.elections[0],
    registrations: (state) => state.registrations,
    voterStats: (state) => state.voterStats,
    electionStats: (state) => state.electionStats,
    candidateStats: (state) => state.candidateStats,
  },
  actions: {
    getElections: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.elections)
        .then((res) => {
          commit("SE_ELECTIONS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("ApiError", "Unable to fetch elections");
        });
    },

    getVoterStats: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.stats(data))
        .then((res) => {
          commit("SET_VOTER_STATS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
        });
    },

    getCandidateStats: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.stats(data))
        .then((res) => {
          commit("SET_CANDIDATE_STATS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
        });
    },

    getElectionStats: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.stats(data))
        .then((res) => {
          commit("SET_ELECTION_STATS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
        });
    },

    getRegistrations: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.voterRegister)
        .then((res) => {
          commit("SET_REGISTRATIONS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("ApiError", "Unable to fetch elections");
        });
    },
  },
};
