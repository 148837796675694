<template>
  <router-view />
</template>
<script>
import { appName } from './environment';

export default {
  mounted() {
    document.title = appName
  }
}
</script>
