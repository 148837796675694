export default {
  login: "auth/login",
  passwordReset: "auth/password-reset",
  setPassword: "auth/setPassword",
  password: "auth/setPassword",
  logout: "auth/logout",
  user: `auth/user`,
  verify: `auth/search`,
  sendOtp: `auth/sendOtp`,
  verifyOtp: `auth/verify`,
  verifyToken: `auth/verifyVoterToken`,
  sendVoterToken: `auth/sendVoterToken`,
};
