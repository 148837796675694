<template>
  <v-container>
    <v-card class="my-5 elevation-0">
      <v-card-title class="primary--text">
        Interim Voters register

        <v-spacer />

        <v-btn
          :href="VoterRegister"
          target="_blank"
          color="primary"
          outlined
          tile
        >
          <v-icon left>mdi-link</v-icon>
          View List
        </v-btn>
      </v-card-title>
      <v-divider />

      <v-row>
        <v-col cols="8" offset="2">
          <v-card color="transparent" tile flat>
            <v-card-title>
              Voter Register
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="register"
              :search="search"
              :items-per-page="20"
              sort-by="firstName"
            >
              <template v-slot:item.no="{ item }">
                {{ register.indexOf(item) + 1 }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { VoterRegister } from "../../../environment";

export default {
  name: "register",
  data() {
    return {
      VoterRegister: VoterRegister,
      search: "",
      headers: [
        {
          text: "Serial No",
          align: "start",
          sortable: false,
          value: "no",
        },
        { text: "First Name", value: "firstName" },
        { text: "Second Name", value: "secondName" },
        { text: "Last Name", value: "lastName" },
        { text: "Category", value: "memberCategory" },
        { text: "Member No.", value: "memberNo" },
      ],
    };
  },

  computed: {
    register() {
      return this.$store.getters["Home/registrations"];
    },
  },
};
</script>

<style scoped></style>
