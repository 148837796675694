import AdminLogin from "./components/AdminLogin";
import AdminAuth from "./views/AdminAuth";
import ForgotPassword from "./components/ForgotPassword.vue";
import SetPassword from "./components/SetPassword.vue";

export default [
  {
    path: "/admin-auth",
    component: AdminAuth,
    children: [
      {
        path: "login",
        name: "AdminLogin",
        component: AdminLogin,
      },
      {
        path: "forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
      },
      {
        path: "set-password/:code",
        name: "AdminSetPassword",
        component: SetPassword,
      },
    ],
  },
];
