<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center">
      <v-col
        cols="12"
        lg="4"
        md="4"
        class="d-flex flex-column justify-center align-center"
      >
        <v-card class="pa-5" width="100%">
          <auth-alert />
          <v-card-title class="text-center">
            <!-- <Logo /> -->
            Scrutineer's Login
          </v-card-title>
          <v-card-text>
            <v-form ref="AdminLogin" v-model="isValid">
              <!--User name-->
              <v-text-field
                ref="email"
                label="Email address"
                :rules="rules.email"
                v-model="formData.email"
                outlined
                placeholder="Enter email address"
              />

              <!-- Password -->
              <v-text-field
                @keyup.enter="login"
                ref="password"
                type="password"
                :rules="rules.password"
                label="Password"
                v-model="formData.password"
                outlined
                placeholder="Enter Password"
              />

              <v-btn
                @keyup.enter="login"
                @click="login"
                color="primary"
                tile
                block
                large
                class="my-5"
              >
                Login
              </v-btn>
              <v-btn
                class="text-left my-3"
                :to="{ name: 'ForgotPassword' }"
                text
              >
                Forgot Password ?
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="primary lighten-1"> Verify OTP </v-card-title>
        <v-card-text class="pa-5">
          <span class="caption">
            Check your phone or email for the OTP Code
          </span>

          <v-otp-input v-model="otp" length="6" @finish="verifyOtp" />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn @click="sendOtp" :loading="$store.getters['loading']">
            Resend
          </v-btn>
          <v-spacer />
          <v-btn color="primary" @click="verifyOtp"> Verify </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AuthAlert from "@/modules/auth/components/authAlert";
import { EventBus } from "@/utils/eventBus";

export default {
  name: "Login",
  components: {
    AuthAlert,
  },
  data: function () {
    return {
      isValid: false,
      dialog: false,
      otp: "",
      user: [],
      formData: {
        email: "",
        password: "",
      },
      rules: {
        email: [
          (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
        ],
        password: [(value) => !!value || "Required."],
      },
      viewPassword: false,
      loading: false,
    };
  },

  mounted() {
    EventBus.$on("otp-verification", (user) => {
      this.user = user;
      this.sendOtp();
    });
  },

  methods: {
    login: function () {
      this.$refs.AdminLogin.validate();
      if (this.isValid) {
        // admin login action
        this.$store.dispatch("AdminAuth/login", this.formData);
      }
    },
    sendOtp: function () {
      this.$store.dispatch("AdminAuth/sendOtp", { ...this.user });
      this.dialog = true;
    },

    verifyOtp: function () {
      this.$store.dispatch("AdminAuth/verifyOtp", { otp: this.otp });
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
@import url("../styles.css");
</style>
