<template>
  <v-app>
    <v-app-bar app color="secondary" dark>
      <a @click="$router.push({ name: 'Home' })">
        <img :src="company?.link" height="50" alt="organization logo"/>
      </a>

      <v-spacer />

      <div v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn :disabled="!election" tile text :to="{ name: 'Results' }"
          >Results</v-btn
        >

        <v-btn :disabled="!election" tile text :to="{ name: 'Candidates' }"
          >Candidates</v-btn
        >
        <v-btn :disabled="!election" tile text :to="{ name: 'Register' }"
          >Voter Register</v-btn
        >

        <v-btn :disabled="!election" tile text :to="{ name: 'MyVote' }"
          >My Vote</v-btn
        >
      </div>

      <v-spacer />

      <v-btn
        :disabled="!election || !canVote || submitted"
        color="primary"
        tile
        :to="{ name: 'Login' }"
        class="mx-2"
      >
        Vote Now
      </v-btn>

      <v-btn text v-if="auth.check()" @click="$store.dispatch('Auth/logout')">
        Logout
        <v-icon right>mdi-logout</v-icon>
      </v-btn>

      <template v-if="$vuetify.breakpoint.smAndDown" v-slot:extension>
        <v-tabs centered show-arrows>
          <v-tab :disabled="!election" :to="{ name: 'Results' }">Results</v-tab>

          <v-tab :disabled="!election" :to="{ name: 'Candidates' }"
            >Candidates</v-tab
          >
          <v-tab :disabled="!election" :to="{ name: 'Register' }"
            >Voter Register</v-tab
          >
          <v-tab :disabled="!election" :to="{ name: 'MyVote' }">My Vote</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-main class="background mx-0">
      <v-sheet color="white" width="100%">
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <v-card tile flat class="py-3">
                <h1>{{ election ? election.description : "No Elections" }}</h1>
                <h5 class="subtitle-1 mb-2">
                  {{
                    election
                      ? election.description2
                      : "The are no ongoing elections this time"
                  }}
                </h5>

                <v-btn
                  :disabled="!election || !canVote || submitted"
                  color="primary"
                  tile
                  :to="{ name: 'Login' }"
                >
                  Vote Now
                </v-btn>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <h5 class="overline text-center primary--text">
                Election Ends In
              </h5>
              <v-chip-group class="float-end">
                <v-chip small>{{ `${countDown.days} Days` }}</v-chip>
                <v-chip small>{{ `${countDown.hours} Hours` }}</v-chip>
                <v-chip small>{{ `${countDown.minutes} Minutes` }}</v-chip>
                <v-chip small>{{ `${countDown.seconds} Seconds` }}</v-chip>
              </v-chip-group>
            </v-col>
            <v-col cols="12" md="4" v-if="$vuetify.breakpoint.mdAndUp">
              <v-img contain max-height="150" src="/images/voting.svg" />
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>

      <router-view />
    </v-main>

    <loader />

    <app-footer />

    <app-alert />
  </v-app>
</template>

<script>
import AppFooter from "@/components/app-footer";
import electionMixin from "@/modules/mixins/electionMixin";
export default {
  name: "home",
  components: { AppFooter },
  mixins: [electionMixin],

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Home/getElections");
      v.$store.dispatch("Home/getRegistrations");
      v.$store.dispatch("Voting/getPollEntry");
      v.$store.dispatch("Setup/getCompany");
    });
  },
  computed: {
    company() {
      return this.$store.getters["Setup/SETUPGETTERS"]("company");
    },
  }
};
</script>

<style scoped></style>
