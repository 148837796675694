import axios from "axios";
import { apiBaseUrl } from "@/environment";
import AdminAuthService from "../../../modules/admin/modules/auth/adminAuthService";

/**
 * Axios basic configuration
 */
const config = {
  baseURL: apiBaseUrl,
};

/**
 * Creating the instance of Axios
 * It is because, in large scale application we may need
 * to consume APIs from more than single server,
 */
const client = axios.create(config);

/**
 * Auth interceptors
 * @description Add auth tokens to every outgoing requests.
 * @param {*} config
 */
const authInterceptor = (config) => {
  if (AdminAuthService.check()) {
    config.headers.Authorization = `Bearer ${AdminAuthService.token}`;
    config.headers.Company = AdminAuthService.company
      ? AdminAuthService.company.id
      : "";
  }
  config.headers.common.Accept = "Application/json";
  config.headers["Access-Control-Allow-Origin"] = "*";
  return config;
};

/**
 * Logger interceptors
 * @description Log app requests.
 * @param {*} config
 */
const loggerInterceptor = (config) =>
  /** Add logging here */
  config;

/** Adding the request interceptors */
client.interceptors.request.use(authInterceptor);
client.interceptors.request.use(loggerInterceptor);

/** Adding the response interceptors */
client.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    // Logout if unauthenticated
    if (error.response.status === 401) AdminAuthService.logout();

    const errorMessage = error.response.data.message;
    error.response.data.message =
      errorMessage.length > 160
        ? JSON.parse(errorMessage.split("code :").pop())
            .error.message.split(":")[0]
            .split("CorrelationId")[0]
        : errorMessage;
    throw error;
  }
);

export default async function call(requestType, url, data = null) {
  // if (!useCryptor) {
  return client[requestType](url, data);
  // }

  // let payload = {
  //   method: requestType,
  //   uri: url,
  //   data: data,
  // };
  //
  // payload = cryptor.encrypt(payload, "allowme@1");
  //
  // const mock = new mocker(axios);
  // let response = {};
  //
  // client["post"](cryptorEndpoint, { payload: payload })
  //   .then((cryptedRes) => {
  //     const decrypted = cryptor.decrypt(cryptedRes.data.data, "allowme@1");
  //
  //     mock.onAny(url).reply(cryptedRes.status, decrypted);
  //
  //     client[requestType](url, data).then((r) => {
  //       response = r.data;
  //     });
  //   })
  //   .catch((err) => {
  //     mock.onAny(url).reply(err.response.statusCode, err.response.data.message);
  //     client[requestType](url, data).then((r) => {
  //       response = r.data;
  //     });
  //   });
  //
  // return response;

  //    var myBlob = new Blob();
  //     var init = { "status" : 200 , "statusText" : "SuperSmashingGreat!" }
  //     var myResponse = new Response(myBlob, init);var myBlob = new Blob();
  //     var init = { "status" : 200 , "statusText" : "SuperSmashingGreat!" }
  //     var myResponse = new Response(myBlob, init);
}
