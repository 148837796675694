<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="12"
        lg="12"
        sm="12"
        v-for="(p, i) in positions"
        :key="i"
      >
        <v-card class="mx-auto" flat tile v-if="candidateStats">
          <v-card-title>
            {{ candidateStats[p.code].datasets[0].label }}
            Tally
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" lg="6" sm="12">
                <BarEcharts
                  :results="candidateStats[p.code]"
                  :position="p.code"
                />
              </v-col>
              <v-col cols="12" md="6" lg="6" sm="12">
                <doughnut-chart :chart-data="candidateStats[p.code]" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mx-auto my-2" flat tile>
      <v-card-title> Total Votes per candidate </v-card-title>
      <v-card-text>
        <CandidateChart :results="totalCandidateStats" :type="'doughnut'" />
      </v-card-text>
    </v-card>
    <v-card class="mx-auto" flat tile>
      <v-card-title> Voting trend </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" lg="12" sm="12">
            <VotingTrend :candidates="candidates" :positions="positions" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import CandidateChart from "./CandidateChart";
import VotingTrend from "./VotingTrend.vue";
import _ from "lodash";
import helpers from "@/utils/helpers/helper.js";
import DoughnutChart from "@/modules/admin/charts/doughnut.js";
import BarEcharts from "./BarEcharts.vue";

export default {
  name: "Dashboard",
  components: { CandidateChart, DoughnutChart, BarEcharts, VotingTrend },
  data: function () {
    return {};
  },
  computed: {
    election() {
      return this.$store.getters["Admin/dashboardGetter"]("election") || [];
    },
    candidates() {
      return this.$store.getters["Admin/dashboardGetter"]("candidates") || [];
    },
    totalCandidateStats() {
      let candidateStats = this.$store.getters["Home/candidateStats"];

      let totalPositionVotes = 0;

      this.candidates.forEach(
        (c) =>
          (totalPositionVotes += parseInt(
            candidateStats[`{${c.no.toUpperCase()}}`]
          ))
      );

      return this.candidates.map((el) => {
        const voteCount = parseInt(candidateStats[`{${el.no.toUpperCase()}}`]);
        el.votes = voteCount;
        el.votePercent = ((voteCount * 100) / totalPositionVotes).toFixed(2);
        return el;
      });
    },
    candidateStats() {
      let positions = {};
      let candidateStats = this.$store.getters["Home/candidateStats"];

      if (!candidateStats) return null;

      this.positions.forEach((p) => {
        positions[p.code] = {
          labels: [],
          datasets: [
            {
              label: "",
              backgroundColor: [],
              data: [],
              bardata: [],
            },
          ],
        };

        positions[p.code].datasets[0].label = p.description;

        let candidates = _.orderBy(
          p.candidateElePositions,
          (c) => c.candidateSequence,
          "asc"
        );

        let totalPositionVotes = 0;

        candidates.forEach(
          (c) => (totalPositionVotes += parseInt(c.voteCount))
        );

        candidates.forEach((c) => {
          positions[p.code].labels.push(
            c.electionCandidates[0].firstName +
              " " +
              c.electionCandidates[0].secondName
          );
          positions[p.code].datasets[0].backgroundColor.push(
            helpers.stringToColour(c.candidateName.split(" ")[0] + "i")
          );
          positions[p.code].datasets[0].data.push(
            ((c.voteCount * 100) / totalPositionVotes).toFixed(2)
          );
          positions[p.code].datasets[0].bardata.push(
            parseInt(candidateStats[`{${c.candidateNo.toUpperCase()}}`])
          );
        });
      });

      return positions;
    },
    positions() {
      return this.election
        ? _.orderBy(this.election.electivePositions, (p) => p.sequence)
        : [];
    },
  },
  methods: {
    percentage(count, total) {
      return parseFloat((count / total) * 100).toFixed(2);
    },
  },
};
</script>
