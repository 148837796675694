import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import home from "./modules/home";
import auth from "./modules/auth";
import voting from "./modules/voting";
import Setup from "./modules/Setup";
import Admin from "./modules/admin";
import appAlert from "./plugins/appAlert";
import confirmation from "./plugins/confirmtion";
import loader from "./plugins/loader";

Vue.config.productionTip = false;

const options = {
  store,
  router,
};

Vue.use(appAlert);
Vue.use(confirmation);
Vue.use(loader);
Vue.use(home, options);
Vue.use(auth, options);
Vue.use(voting, options);
Vue.use(Admin, options);
Vue.use(Setup, options);

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
