<template>
  <v-container class="my-7">
    <v-card flat tile>
      <v-card-title>
        <v-spacer />
        My Votes
        <v-spacer />
      </v-card-title>

      <v-card-actions>
        <v-row>
          <v-col cols="12" md="4" v-for="(voted, i) in votingEntry" :key="i">
            <v-list three-line>
              <v-list-item-group>
                <v-list-item>
                  <v-list-item-avatar size="70" tile>
                    <v-img
                      aspect-ratio="1"
                      contain
                      lazy-src="/images/loading.gif"
                      :src="voted.link"
                    />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <v-chip small>
                        {{ voted.electivePositions.description }}
                      </v-chip>
                    </v-list-item-subtitle>
                    <v-list-item-title class="text-uppercase">
                      {{
                        `${voted.electionCandidates[0].title} ${voted.electionCandidates[0].firstName} ${voted.electionCandidates[0].secondName} ${voted.electionCandidates[0].lastName}`
                      }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Voted at: {{ formatDate(voted.votedTime, 7) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import DateMixin from "@/mixin/DateMixin";
import _ from "lodash";

export default {
  name: "myVote",
  mixins: [DateMixin],

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Voting/getElections");
      v.$store.dispatch("Voting/getPollEntry");
    });
  },

  computed: {
    pollEntry() {
      return this.$store.getters["Voting/pollEntry"];
    },

    votingEntry() {
      return this.pollEntry
        ? _.orderBy(
            this.pollEntry.votingEntry,
            (p) => p.electivePositions.sequence
          )
        : [];
    },
  },
};
</script>

<style scoped></style>
