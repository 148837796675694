import call from "@/service/http/client/admin.js";
import constants from "./DashboardConstants";

export default {
  namespaced: true,
  state: {
    entries: [],
    candidates: [],
    positions: [],
    electionResults: [],
    election: [],
  },
  mutations: {
    MUTATE: (state, payload) => [(state[payload.state] = payload.data)],
  },
  getters: {
    dashboardGetter: (state) => (val) => state[val],
  },
  actions: {
    getResults: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.results)
        .then((res) => {
          commit("MUTATE", {
            state: "candidates",
            data: res.data.data.candidates,
          });
          commit("MUTATE", {
            state: "electionResults",
            data: res.data.data.electionResults,
          });
          commit("MUTATE", {
            state: "election",
            data: res.data.data.election,
          });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("SET_LOADING", false, { root: true });
          console.log(error.response.data.message);
        });
    },
  },
};
