<template>
  <v-container v-if="election && election.electivePositions">
    <v-card flat tile class="my-7">
      <v-card-title class="primary--text">
        Election Positions & Candidates
      </v-card-title>

      <v-expansion-panels v-model="panel" multiple class="active">
        <v-expansion-panel v-for="(pos, u) in positions" :key="u">
          <v-expansion-panel-header :class="panel === u ? 'white--text' : ''">
            {{ pos.description }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pb-5">
            <v-row>
              <v-col cols="12" md="3" class="d-flex flex-column text-center">
                <h3 class="text-h5">{{ pos.description }}</h3>
                <br />
                <h3 class="subtitle-2 grey--text">
                  {{ pos.maxPositions }} Seat
                  {{ `${pos.maxPositions > 1 ? "s" : ""}` }} ,
                  {{ pos.candidateElePositions.length }} Candidates,
                </h3>
              </v-col>
              <v-col cols="12" md="9" class="d-flex">
                <v-row>
                  <v-col
                    cols="6"
                    md="3"
                    v-for="(candidate, i) in loadash.orderBy(
                      pos.candidateElePositions,
                      (c) => c.candidateSequence
                    )"
                    :key="i"
                  >
                    <v-card @click="openCandidate(candidate)">
                      <v-card-subtitle class="overline pa-0 ma-0 text-center">
                        {{ candidate.electionCandidates[0].memberNo || "" }}
                      </v-card-subtitle>
                      <v-img
                        aspect-ratio="1/1"
                        height="150px"
                        contain
                        lazy-src="/images/loading.gif"
                        :src="candidate.link"
                      />
                      <v-card-subtitle
                        class="caption pa-0 ma-0 d-flex flex-row justify-center"
                      >
                        <v-chip-group>
                          <v-chip
                            x-small
                            v-if="
                              candidate.electionCandidates[0].memberCategory
                            "
                          >
                            {{
                              candidate.electionCandidates[0].memberCategory ||
                              ""
                            }}
                          </v-chip>
                          <v-chip
                            x-small
                            v-if="candidate.electionCandidates[0].discipline"
                          >
                            {{
                              candidate.electionCandidates[0].discipline || ""
                            }}
                          </v-chip>
                        </v-chip-group>
                      </v-card-subtitle>
                      <v-card-subtitle class="py-1 text-uppercase">
                        {{ candidate.electionCandidates[0].title }}
                        {{ candidate.candidateName }}
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <v-dialog v-model="dialog" width="600px">
      <v-card v-if="selectedCandidate">
        <v-card-title>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-img
                  aspect-ratio="1"
                  contain
                  lazy-src="/images/loading.gif"
                  :src="selectedCandidate.link"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="overline">
                  {{ selectedCandidate.electionCandidates[0].title }}
                  {{ selectedCandidate.candidateName }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-title>

        <v-card-text>
          {{ selectedCandidate.electionCandidates[0].aboutCandidate }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="green darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";

export default {
  name: "candidates",
  data: function () {
    return {
      panel: [0],
      dialog: false,
      selectedCandidate: null,
    };
  },

  computed: {
    loadash() {
      return _;
    },

    positions() {
      return this.election
        ? _.orderBy(this.election.electivePositions, (p) => p.sequence)
        : [];
    },

    election() {
      return this.$store.getters["Home/elections"];
    },
  },

  methods: {
    openCandidate: function (candidate) {
      this.selectedCandidate = { ...candidate };
      this.dialog = true;
    },
  },

  watch: {
    positions: {
      handler: function () {
        this.panel = [];
        this.positions.forEach((p, i) => {
          this.panel.push(i);
        });
      },
    },
  },
};
</script>

<style scoped></style>
