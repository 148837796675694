import home from "@/modules/home/views/home";
import candidates from "@/modules/home/components/candidates";
import register from "@/modules/home/components/register";
import results from "@/modules/home/components/results";
import myVote from "@/modules/home/components/myVote";

export default [
  {
    path: "/home",
    component: home,
    children: [
      {
        path: "",
        name: "Home",
        redirect: "/home/results",
      },

      {
        path: "results",
        name: "Results",
        component: results,
      },

      {
        path: "candidates",
        name: "Candidates",
        component: candidates,
      },

      {
        path: "register",
        name: "Register",
        component: register,
      },

      {
        path: "my-vote",
        name: "MyVote",
        component: myVote,
      },
    ],
  },
];
