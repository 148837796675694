<template>
  <v-app>
    <v-app-bar app color="secondary" dark>
      <a @click="$router.push({ name: 'Home' })">
        <img :src="company?.link" height="50" alt="organization logo" />
      </a>

      <v-spacer />

      <v-chip-group class="float-end">
        <v-chip small>{{ `${countDown.days} Days` }}</v-chip>
        <v-chip small>{{ `${countDown.hours} Hours` }}</v-chip>
        <v-chip small>{{ `${countDown.minutes} Minutes` }}</v-chip>
        <v-chip small>{{ `${countDown.seconds} Seconds` }}</v-chip>
      </v-chip-group>

      <v-spacer />

      <v-btn text v-if="auth.check()" @click="$store.dispatch('Auth/logout')">
        Logout
        <v-icon right>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main class="background">
      <router-view />
    </v-main>

    <loader />

    <confirmation />

    <app-alert />

    <Footer />
  </v-app>
</template>

<script>
import electionMixin from "@/modules/mixins/electionMixin";
import Footer from "@/components/Footer";

export default {
  name: "votingLayout",
  components: { Footer },
  mixins: [electionMixin],

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Voting/getElections");
      v.$store.dispatch("Voting/getPollEntry");
      v.$store.dispatch("Setup/getCompany");
    });
  },

  computed: {
    election() {
      return this.$store.getters["Voting/elections"];
    },
    company() {
      return this.$store.getters["Setup/SETUPGETTERS"]("company");
    },
  },
};
</script>

<style scoped></style>
