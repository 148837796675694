<template>
  <div id="trend" style="width: 100%; height: 100vh"></div>
</template>
<script>
// import LineChart from "@/modules/admin/charts/line.js";
import helpers from "@/utils/helpers/helper.js";
import * as echarts from "echarts";

export default {
  name: "VotingTrend",
  props: { candidates: Array, positions: Array },
  components: {},
  data: function () {
    return {};
  },
  watch: {
    results() {
      if (this.results.length !== 0) {
        this.myChart.setOption(this.option);
      }
    },
  },
  computed: {
    chartDom() {
      return document.getElementById("trend");
    },
    myChart() {
      return echarts.init(this.chartDom);
    },
    results() {
      return this.$store.getters["Admin/dashboardGetter"]("electionResults");
    },
    date() {
      return [
        helpers.getDateTimeFormat(this.results[0].fromTime),
        ...this.results.map((el) => helpers.getDateTimeFormat(el.toTime)),
      ];
    },
    data() {
      return [0, ...this.results.map((el) => el.votes)];
    },
    option() {
      return {
        tooltip: {
          trigger: "axis",
          position: function (pt) {
            return [pt[0], "10%"];
          },
        },
        title: {
          left: "center",
          text: "Voting trend for total votes",
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.date,
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "100%"],
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 10,
          },
          {
            start: 0,
            end: 10,
          },
        ],
        series: [
          {
            name: "Voting Trend",
            type: "line",
            symbol: "none",
            sampling: "lttb",
            itemStyle: {
              color: "rgb(255, 70, 131)",
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(255, 158, 68)",
                },
                {
                  offset: 1,
                  color: "rgb(255, 70, 131)",
                },
              ]),
            },
            data: this.data,
          },
        ],
      };
    },
  },
  methods: {},
};
</script>
