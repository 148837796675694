import call from "../../service/http";
import constants from "./constants";
import { EventBus } from "@/utils/eventBus";

export default {
  namespaced: true,
  state: {
    company: {},
  },
  getters: {
    SETUPGETTERS: (state) => (payload) => state[payload],
  },
  mutations: {
    SETUPMUTATIONS: (state, payload) => state[payload.type] = payload['data']
  },
  actions: {
    getCompany({ commit }) {
      call("get", constants.company)
        .then((res) => {
          commit("SETUPMUTATIONS", { type: "company", data: res?.data.data });
        })
        .catch((error) => {
          EventBus.$emit("ApiError", "Unable to fetch company", error);
        });
    },
  },
};
