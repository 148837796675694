import { Doughnut, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  props: ["options"],
  mounted() {
    const options = {
      legend: {
        position: "right",
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            let label = data.labels[tooltipItem.index] || "";

            if (label) {
              label += ": ";
            }
            label +=
              Math.round(data.datasets[0].data[tooltipItem.index] * 100) / 100;
            return label + "%";
          },
        },
      },
    };
    this.renderChart(this.chartData, options);
  },
};
